import Request from './index';
import STORAGE_NAME from '@/const/storage';
import storeRequest from './request/storeRequest';
const catalogRequest = new Request();
const domain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
if (domain) {
  if (domain.includes('.cybersilk.xyz')) catalogRequest.setBaseURL('https://happystore.cybersilk.xyz/api');
  else catalogRequest.setBaseURL('https://happystock.lattehub.com/api');
} else catalogRequest.setBaseURL('https://happystock.lattehub.com/api');

export const catalogService = {
  getCatalogs() {
    return catalogRequest.get('/collections/public/all?limit=50&page=1');
  },
  getProductsByCatalog({ from, size, request, catalogId }) {
    const url = `/vue/catalog/vue_storefront_catalog/product/_search?from=${from}&request=${request}&size=${size}&sort=orderInCollection.${catalogId}:asc`;
    return catalogRequest.get(url);
  },
  importProductToStore(productId) {
    return storeRequest.post(`products/${productId}/clone`);
  },
};
