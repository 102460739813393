<template>
  <div id="catalog-page">
    <div class="d-flex align-start justify-space-between mb-2">
      <h2 style="font-size: 32px;">Catalog</h2>
      <!-- <v-btn
        color="primary"
        class="font-weight-medium rounded-lg"
        :disabled="selectedList.length === 0"
        @click="onImportProducts"
        large
        depressed
      >
        <v-icon> mdi-import </v-icon>Import
        <span v-if="selectedList.length">{{ selectedList.length }} selected products</span> to store</v-btn
      > -->
    </div>
    <p style="color: #5e6a6e">
      New products are updated everyday. Feel free to find any products that suit your business.
    </p>
    <v-row no-gutters class="mx-0" style="gap: 12px;">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-text-field
          placeholder="Search by product name"
          autocomplete="off"
          prepend-inner-icon="mdi-magnify pointer"
          v-model.trim="model.searchText"
          @input="onSearchProducts"
        />
      </v-col>
    </v-row>
    <v-chip-group
      v-if="catalogs.length"
      active-class="primary--text"
      class="mb-4"
      v-model="model.catalog"
      @change="onSelectCatalog"
      column
      mandatory
    >
      <v-chip
        v-for="catalog in catalogs"
        :key="catalog._id"
        class="rounded-lg"
        style="height: 44px;"
        :value="catalog"
        outlined
      >
        {{ catalog.title }}
      </v-chip>
    </v-chip-group>
    <div v-if="isLoading" class="d-flex justify-center align-center" style="height:400px">
      <div class="text-center" style="width:fit-content">
        <page-loader />
        <p class="mt-4 mb-0 primary--text">Loading catalogs...</p>
      </div>
    </div>
    <ul v-if="productList.length && !isLoading" class="product-list mb-8">
      <li v-for="product in productList" :key="product.id" class="product" :title="product.name.trim()">
        <!-- <span class="mdi mdi-check-circle primary--text"></span> -->
        <div class="product-image mb-2">
          <img :src="product.image" :alt="product.name" />
        </div>
        <h4 class="product-name mb-2">{{ product.name.trim() }}</h4>
        <div class="product-price mb-3">
          <div class="text-center">
            <label>Cost (Ship to US)</label>
            <p class="mb-0">{{ parseCost(product.sellingPrice) }}</p>
          </div>
          <div class="text-center">
            <label>Selling price</label>
            <p class="mb-0">{{ formatPrice(product.sellingPrice) }}</p>
          </div>
        </div>
        <v-btn
          class="product-btn-import"
          color="#e1e1e1"
          :loading="product.isLoadingImport"
          @click="onImportProduct(product)"
          block
          depressed
          >import to store</v-btn
        >
      </li>
    </ul>
    <div
      v-if="productList.length && !isLoading"
      class="d-flex flex-column flex-sm-row align-center align-sm-start justify-sm-space-between"
    >
      <!-- <v-checkbox
        v-show="false"
        label="Choose all products"
        v-model="model.chooseAllProducts"
        @change="onChooseAllProducts"
      /> -->
      <v-select
        :items="valueLimits"
        v-model="pagination.limit"
        style="max-width: 170px;"
        suffix="/ page"
        @change="getProductsByCatalog"
        dense
      />
      <v-pagination
        :limitAdd="10"
        :visible="7"
        :length="pagination.length"
        :total-visible="10"
        v-model="pagination.page"
        @input="getProductsByCatalog"
      ></v-pagination>
    </div>
    <div v-if="productList.length === 0 && !isLoading" class="no-data">
      <span class="mdi mdi-cube-outline"></span>
      <p>There are no catalogs</p>
    </div>
  </div>
</template>

<script>
import { catalogService } from '@/apis/catalog';
export default {
  data() {
    return {
      catalogs: [],
      productList: [],
      selectedList: [],
      valueLimits: [20, 40, 60, 100],
      model: {
        catalog: null,
        chooseAllProducts: false,
        searchText: null,
      },
      isLoading: true,
      pagination: {
        page: 1,
        length: 1,
        limit: 20,
      },
    };
  },
  computed: {
    fromPagination() {
      return (Number(this.pagination.page) - 1) * Number(this.pagination.limit);
    },
  },
  methods: {
    getPagination(page, size) {
      const start = (Number(page) - 1) * Number(size);
      return start;
    },
    async getCatalogs() {
      try {
        this.isLoading = true;
        const { data } = await catalogService.getCatalogs();
        if (data?.collections?.length) {
          const defaultCatalog = data.collections.find(catalog => catalog.handle == 'all-products');
          if (defaultCatalog) {
            this.catalogs.unshift(defaultCatalog);
            this.model.catalog = { ...defaultCatalog };
            const catalogs = data.collections.filter(catalog => catalog.handle != 'all-products');
            this.catalogs.push(...catalogs);
          } else {
            this.catalogs.push(...data.collections);
            this.model.catalog = { ...data.collections[0] };
          }
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async getProductsByCatalog() {
      try {
        this.isLoading = true;
        const rules = JSON.stringify({
          _id: this.model.catalog._id,
          disjunctive: this.model.catalog.disjunctive,
          collectionType: this.model.catalog.collectionType,
          rules: this.model.catalog.rules,
        });
        const request = {
          query: {
            bool: {
              filter: {
                bool: {
                  must: [
                    { terms: { visibility: [2, 3, 4] } },
                    { terms: { status: [0, 1] } },
                    {
                      terms: {
                        rules,
                      },
                    },
                    { terms: { category_ids: [this.model.catalog._id] } },
                  ],
                },
              },
            },
          },
        };
        const enCodeRequest = encodeURIComponent(JSON.stringify(request));
        const queryParams = {
          from: this.fromPagination,
          size: this.pagination.limit,
          catalogId: this.model.catalog._id,
          request: enCodeRequest,
        };
        const { data } = await catalogService.getProductsByCatalog(queryParams);
        if (data?.hits?.hits.length) {
          const products = data.hits.hits.map(product => {
            const image = product._source.image.includes('https://')
              ? product._source.image
              : `https://minio.lattehub.com/img/320/0/resize${product._source.image}`;
            return {
              id: product._id,
              name: product._source.name,
              sellingPrice: product._source.firstVariant.original_final_price,
              price: 0,
              image,
              isLoadingImport: false,
            };
          });
          this.productList = products;
          this.pagination.length = Math.ceil(data.hits.total.value / this.pagination.limit);
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:227 ~ getProductsByCatalog ~ error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    // isSelected(productId) {
    //   return this.selectedList.some(item => item.id === productId);
    // },
    // onSelectProduct(product) {
    //   const index = this.selectedList.findIndex(item => item.id === product.id);
    //   if (index === -1) this.selectedList.push(product);
    //   else this.selectedList.splice(index, 1);
    // },
    // async onImportProducts() {
    //   try {
    //     console.log('List: ', this.selectedList);
    //   } catch (error) {}
    // },
    async onImportProduct(product) {
      try {
        product.isLoadingImport = true;
        await catalogService.importProductToStore(product.id);
      } catch (error) {
        console.log('🚀 ~ file: index.vue:186 ~ onImportProduct ~ error:', error);
      } finally {
        product.isLoadingImport = false;
      }
    },
    // onChooseAllProducts(bool) {
    //   if (bool) {
    //     this.selectedList = [...this.productList];
    //   } else {
    //     this.selectedList = [];
    //   }
    // },
    formatPrice(price) {
      if (!price) return '$0';
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
    },
    onSelectCatalog() {
      this.pagination.page = 1;
      this.pagination.limit = 20;
      this.getProductsByCatalog();
    },
    async onSearchProducts() {
      if (this.model.searchText) {
        try {
          this.isLoading = true;
          const searchText = this.model.searchText;
          const request = {
            query: {
              bool: {
                filter: { bool: { must: [{ terms: { visibility: [3, 4] } }, { terms: { status: [0, 1] } }] } },
                must: {
                  function_score: {
                    functions: [{ filter: { match: { attribute_code: 'attribute_value' } }, weight: 1 }],
                    score_mode: 'multiply',
                    boost_mode: 'multiply',
                    max_boost: 100,
                    min_score: 1,
                    query: {
                      bool: {
                        should: [
                          {
                            multi_match: {
                              fields: ['name^4', 'sku^2', 'category.name^1'],
                              query: searchText,
                              operator: 'or',
                              fuzziness: 2,
                              cutoff_frequency: 0.01,
                              max_expansions: 3,
                              prefix_length: 2,
                              minimum_should_match: '75%25',
                              tie_breaker: '1',
                            },
                          },
                          {
                            bool: {
                              should: [
                                { terms: { 'configurable_children.sku': [searchText] } },
                                { match_phrase: { sku: { query: searchText, boost: 1 } } },
                                { match_phrase: { 'configurable_children.sku': { query: searchText, boost: 1 } } },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  },
                },
              },
            },
            min_score: 0.02,
          };
          const enCodeRequest = encodeURIComponent(JSON.stringify(request));
          const queryParams = {
            from: this.fromPagination,
            size: this.pagination.limit,
            catalogId: this.model.catalog._id,
            request: enCodeRequest,
          };
          const { data } = await catalogService.getProductsByCatalog(queryParams);
          if (data?.hits?.hits.length) {
            const products = data.hits.hits.map(product => {
              const image = product._source.image.includes('https://')
                ? product._source.image
                : `https://minio.lattehub.com/img/320/0/resize${product._source.image}`;
              return {
                id: product._id,
                name: product._source.name,
                sellingPrice: product._source.firstVariant.original_final_price,
                price: 0,
                image,
                isLoadingImport: false,
              };
            });
            this.productList = products;
            this.pagination.length = Math.ceil(data.hits.total.value / this.pagination.limit);
          }
        } catch (error) {
          console.log('🚀 ~ file: index.vue:347 ~ onSearchProducts ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      } else this.getProductsByCatalog();
    },
    parseCost(price) {
      const from = this.formatPrice(0.25 * Number(price));
      const to = this.formatPrice(0.27 * Number(price));
      return `${from} ~ ${to}`;
    },
  },
  async created() {
    await this.getCatalogs();
    await this.getProductsByCatalog();
  },
};
</script>

<style lang="scss" scoped>
#catalog-page {
  ul.product-list {
    padding-left: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 2rem;
    @media screen and (min-width: 600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (min-width: 960px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    li.product {
      position: relative;
      // &.product-selected {
      //   span.mdi {
      //     display: block;
      //   }
      // }
      // span.mdi {
      //   position: absolute;
      //   font-size: 24px;
      //   line-height: 24px;
      //   right: -12px;
      //   top: -12px;
      //   z-index: 1;
      //   display: none;
      // }
      .product-image {
        width: 100%;
        overflow: hidden;
        border-radius: 8px;
        aspect-ratio: 1 / 1;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .product-name {
        font-weight: 500;
        color: #374151;
        overflow: hidden;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap; /* let the text wrap preserving spaces */
        min-height: 42px;
      }
      .product-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
          color: rgb(85, 85, 85);
          font-size: 13px;
        }
        p,
        a {
          font-weight: 500;
          font-size: 1rem;
          display: block;
        }
      }
    }
  }
  .no-data {
    text-align: center;
    color: #676767;
    margin-top: 150px;
    span {
      font-size: 48px;
    }
    p {
      font-size: 16px;
    }
  }
}
::v-deep .v-pagination li button {
  box-shadow: none;
  border: solid 1px #dadce0;
  border-radius: 8px;
}
::v-deep .product-btn-import {
  height: 38px !important;
  background-color: #e1e1e1;
  border-radius: 8px;
  .v-btn__content {
    text-transform: capitalize;
    color: rgb(17, 24, 39);
    font-weight: 500;
    font-size: 14px;
  }
}
::v-deep .v-input .v-text-field__suffix {
  font-weight: 400 !important;
}
</style>
